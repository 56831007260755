import React from 'react';
import './Gift.css';

const GiftVoucher = ({ setShowTerms }) => {
    const handleTermsClick = (e) => {
        e.preventDefault(); // Prevent default link behavior
        setShowTerms(true); // Set showTerms to true
    };
    return (
        <div className="gift-voucher">
            <p className="voucher-text">
                Give the gift of relaxation – we sell gift vouchers directly from our front desk or, for those wishing to purchase remotely, we can arrange an electronic voucher. For electronic vouchers, we require payment to be made either via CLIQ. Please inquire by getting in touch with us by call or WhatsApp 0777-155177.
            </p>
            <div className="terms-gift">
                <span className="terms-text">For terms and conditions, </span>
                <a href='/terms' className="terms-link" onClick={handleTermsClick}>click here.</a>
            </div>
        </div>
    );
};

export default GiftVoucher;
