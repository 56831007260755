import React from "react";
import styled from "styled-components";
import img from "../assets/snap.png";
import { ContactInfo } from "../data/contactInfo";
import { PiMapPinLight, PiPhoneCallThin } from "react-icons/pi";
import { useLocation } from 'react-router-dom';

// Function to detect if the user is on a mobile device
const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
};

const Contact = () => {
    const data = ContactInfo;
    const location = useLocation();

    // Function to handle calling on mobile
    const handleCall = (tel) => {
        if (isMobileDevice()) {
            window.location.href = `tel:${tel}`;
        }
    };

    // Function to open map
    const handleOpenMap = (src) => {
        window.open(src, '_blank');
    };

    return (
        <Wrap>
            <Container>
                <CardContact>
                    {data.map((x, index) => (
                        <Branch key={index}>
                            <TextBox>
                                <BoxTitle>{x.branch}</BoxTitle>
                                <Box onClick={() => handleOpenMap(x.src)}>
                                    <AddressText>
                                        <PiMapPinLight style={{ fontSize: '18px', marginRight: '6px' }} />
                                        <div>{x.location.address}</div>
                                    </AddressText>
                                </Box>
                                <Box onClick={() => handleCall(x.tel)}>
                                    <AddressText>
                                        <PiPhoneCallThin style={{ fontSize: '18px', marginRight: '8px' }} />
                                        {x.tel}
                                    </AddressText>
                                </Box>
                            </TextBox>
                        </Branch>
                    ))}
                </CardContact>
            </Container>
        </Wrap>
    );
};

const Wrap = styled.div`
    background-color: white;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: black;
    padding: 0;
    position: relative;
    display: flex;
    width: 100vw;
    height: auto; 
    padding: 50px 20px 80px 20px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
`;

const CardContact = styled.div`
    background-color: rgba(255, 255, 255, 0.1);
    width: 95vw;
    height: 100%;
    display: flex;
    flex-direction: row;
    text-align: left;
    justify-content: space-between; 

    @media (max-width: 768px) {
        flex-direction: column;
        margin-left: 55px;
    }
`;

const Branch = styled.div`
    max-width: 600px; 
    letter-spacing: 1px;
    border-radius: 1%;
    padding: 10px 30px;

    @media (max-width: 768px) {
        padding: 30px 0;
        margin-top: 30px;
    }
`;

const BoxTitle = styled.div`
    margin-bottom: 10px;
    font-size: 15px;
`;

const Box = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: end;
    cursor: pointer;

    @media (max-width: 768px) {
        font-size: 15px;
        letter-spacing: 1px;
    }
`;

const AddressText = styled.div`
    display: flex;
    flex-direction: row;
    text-align: left;
    align-items: end;
    margin: 10px 0;

    &:hover {
        color: blue; 
    }
`;

const TextBox = styled.div`
    display: flex;
    flex-direction: column; 
    min-width: 90%;
    margin-left: 0;
    color: #25595c;
    font-weight: 200;
    text-align: left;
`;

export default Contact;
