import React from 'react';
import './Terms.css';

const Terms = ({ onClose }) => {
    return (
        <div className="terms-wrapper" onClick={onClose}>
            <div className="terms-overlay"></div>
            <div className="terms-card" onClick={(e) => e.stopPropagation()}>
                <button className="close-button" onClick={onClose}>X</button>
                <h2 className="terms-title">Spa Etiquette</h2>
                <div>Please arrive at least 10 minutes before your treatment is due to start.</div>
                <div>Please understand that if you are late, we may need to cut your treatment time accordingly if we are fully booked.</div>
                <div>
                    Please help us maintain an ambience of relaxation by using a quiet voice and being mindful of other guests – no phones
                    are to be used beyond the lobby area.
                </div>
                <div>Le Spa is not responsible for the loss of personal belongings - please ask your therapist for a basket.</div>
                <div><br />Thank you for your cooperation.</div>
                <div className="divider"></div>
                <h2 className="terms-title">Gift Vouchers Terms and Conditions</h2>
                <ul className="term-voucher">
                    <li>Vouchers are non-refundable / not redeemable for cash.</li>
                    <li>Vouchers are valid for three months from the date of issue.</li>
                    <li>Vouchers are redeemable for spa services (not for retail).</li>
                    <li>Vouchers are non-transferable to other recipients.</li>
                    <li>
                        Changes to the treatment type on the voucher are permitted, however, any difference in charges must be paid additionally.
                    </li>
                </ul>
                <div className="divider"></div>
                <p>For more info, feel free to reach out at 0777-155177</p>
            </div>
        </div>
    );
};

export default Terms;
