import React from 'react';
import { menuFace } from '../assets/data/menuFace.js';
import './Face.css';

const Face = ({ setShowTerms }) => { // Accept setShowTerms as a prop
    const handleTermsClick = (e) => {
        e.preventDefault(); // Prevent default link behavior
        setShowTerms(true); // Set showTerms to true
    };

    return (
        <div className="face-container">
            <div className="table-header">
                <div className="header-item">Treatment</div>
                <div className="header-item">Min</div>
                <div className="header-item">Price</div>
            </div>
            <div className="face-menu">
                {menuFace.map((item, index) => (
                    <div key={index} className="menu-item">
                        <div className="facial">{item.facial}</div>
                        <div className="minutes">{item.minutes}</div>
                        <div className="price">{item.price ? item.price : 'N/A'}</div>
                    </div>
                ))}
            </div>
            <div className="terms">
                <span>
                    To book an appointment, call or WhatsApp 0777-155177.
                    <br />
                    For spa etiquette, please{' '}
                </span>
                <a href="/terms" className="terms-link" onClick={handleTermsClick}>
                    click here.
                </a>
            </div>
        </div>
    );
};

export default Face;
