import React from 'react';
import { menuBody } from '../assets/data/menuBody'
import './Face.css';

const Body = ({ setShowTerms }) => {
    const handleTermsClick = (e) => {
        e.preventDefault(); // Prevent default link behavior
        setShowTerms(true); // Set showTerms to true
    };
    return (
        <div className="face-container">
            <div className="table-header">
                <div className="header-item">Treatment</div>
                <div className="header-item">Min</div>
                <div className="header-item">Price</div>
            </div>
            <div className="face-menu">
                {menuBody.map((item, index) => (
                    <div key={index} className="menu-item">
                        <div className="facial">{item.treatment}</div>
                        <div className="minutes">{item.minutes}</div>
                        <div className="price">{item.price ? item.price : ''}</div>
                    </div>
                ))}
            </div>
            <div className="terms">
                <span >To book an appointment, call or WhatsApp 0777-155177.
                    <br></br>For spa etiquette, please  </span>
                <a href="/terms" className="terms-link" onClick={handleTermsClick}>click here.</a>
            </div>
        </div>
    );
};

export default Body;
