export const menuFace = [
    { facial: "Deep C.", minutes: "60 – 75", price: "50 / 60" },
    { facial: "HydraFacial", minutes: 60, price: 60 },
    { facial: "Activator", minutes: 60, price: 70 },
    { facial: "Prodige", minutes: 60, price: 70 },
    { facial: "Derma Pen", minutes: 60, price: 70 },
    { facial: "Derma Scalp", minutes: 35, price: 50 },
    { facial: "Derma Eye", minutes: 25, price: 40 },
    { facial: "Oxy Collagen", minutes: 75, price: 80 },
    { facial: "Face Frax", minutes: 75, price: 80 },
    { facial: "Microneedling", minutes: 75, price: 80 },
    { facial: "Miso", minutes: 45, price: 80 },
    { facial: "Miso+", minutes: 75, price: 90 },
    { facial: "PRX", minutes: 35, price: 80 },
    { facial: "Face Massage", minutes: 30, price: 30 },
    { facial: "Pick Me Up", minutes: 45, price: 45 },
    { facial: "Mini Facial", minutes: 55, price: "50 / 65" }
];
