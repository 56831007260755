import React from 'react';
import './Events.css'; // Ensure to create this CSS file for styles
import eventImg from '../assets/photos/66.jpeg'

import "./Events.css"

const Events = () => {
    return (
        <div className="events-container">
            <div className="events-image-container">
                <div className='events-image'>
                    <img src={eventImg} alt="Bachelorette party" className='events-img'></img>
                </div>
            </div>
            <p className="events-text">
                Whether you’re planning a bridal shower, birthday celebration, or a relaxing gathering with close friends,
                you can book Le Spa for a private event with your favorite ladies. Enjoy exclusive access to our tranquil space,
                where you and your guests can indulge in tailored spa treatments, unwind in a peaceful atmosphere, and create lasting memories.
                Contact us for more info : 0777-155177.</p>




        </div >
    );
};

export default Events;
