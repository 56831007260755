

import React from 'react';
import './Landing.css';
import spaImg from "../assets/logotwo.png";
import { PiArrowDownThin } from "react-icons/pi";


const Landing = () => {
    const scrollToAbout = () => {
        const aboutSection = document.getElementById('about-section');
        if (aboutSection) {
            aboutSection.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <div className="landing-container" onClick={scrollToAbout}>
            <video autoPlay loop muted className="landing-video">
                <source src={require('../assets/waterloop.mp4')} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className="quote-container">
                <img src={spaImg} alt="Le Spa" className="spaImg" />
                <h2>"You are not a drop in the ocean;<br />you are the entire ocean in a drop"</h2>
            </div>

            <div className='arrow' >
                <PiArrowDownThin />
            </div>
        </div>
    );
};

export default Landing;
