import React, { useState } from 'react';
import { BrowserRouter as Router, useNavigate, useLocation } from 'react-router-dom';
import Landing from './components/Landing';
import About from './components/About';
import Services from './components/Services';
import Contact from './components/Contact';
import Terms from './components/Terms';
import Footer from './components/Footer'; // Corrected import path
import './App.css';

const AppContent = () => {
  const [showTerms, setShowTerms] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    setShowTerms(location.pathname === '/terms');
  }, [location.pathname]);

  const closeTerms = () => {
    setShowTerms(false);
    navigate('/');
  };

  return (
    <div className="app-container">
      <main className="main-content">
        <Landing />
        <div id="about-section">
          <About />
        </div>
        <Services setShowTerms={setShowTerms} />
        <Contact />
        {showTerms && <Terms onClose={closeTerms} />}
      </main>
      <Footer /> {/* Add Footer here */}
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;
