// About.js
import React, { useEffect, useState } from 'react';
import './About.css';
import img1 from '../assets/photos/1.jpeg';
import img2 from '../assets/photos/2.jpeg';
import img3 from '../assets/photos/3.jpeg';
import img4 from '../assets/photos/4.jpeg';
import img5 from '../assets/photos/5.jpeg';
import img6 from '../assets/photos/6.jpeg';
import img7 from '../assets/photos/7.jpeg';
import img8 from '../assets/photos/8.jpeg';
import img9 from '../assets/photos/9.jpeg';
import img10 from '../assets/photos/10.jpeg';
import img11 from '../assets/photos/11.jpeg';
import img12 from '../assets/photos/12.jpeg';
import img13 from '../assets/photos/13.jpeg';
import img14 from '../assets/photos/14.jpeg';

const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14];

const About = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 2000); // Change image every 3 seconds

        return () => clearInterval(interval); // Clear interval on component unmount
    }, []);

    return (
        <div className="about-container">
            <div className="about-content">
                <div className="about-image">
                    <img
                        src={images[currentImageIndex]}
                        alt={`Le Spa ${currentImageIndex + 1}`}
                        className="slideshow-image"
                    />
                </div>
                <div className="about-text">
                    <div className="about-heading">
                        Welcome to Le Spa, a sanctuary for holistic wellness nestled in the heart of Abdoun and Sweifieh Village.
                    </div>
                    <div className="about-description">
                        Enjoy personalized therapies designed to cultivate a profound sense of inner and outer well-being. Heal your body, the temple of your soul, with soothing massages, revitalizing facial treatments, and invigorating body scrubs or wraps that harmonize mind, body, and soul.                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
