export const ContactInfo = [
    {
        "branch": "Le Spa Abdoun",
        "tel": "+962-777-155177",
        "address": "11 Fawzi al Kawikji street, Amman, Jordan",
        "src": "https://maps.app.goo.gl/NLM7bn2hxGNdaDhK7",
        "location": {
            "address": '11 Fawzi al Kawikji street, Amman.',
            "lat": "31.953910034639737",
            "lng": "35.883718770960826"
        },
        "img": require("../assets/branchphotos/a.jpg"), // Use require to dynamically import the image
    },
    {
        "branch": "Le Spa @ The Village",
        "tel": "+962-79-8889588",
        "address": "Swefieh Village, Amman",
        "src": "https://maps.app.goo.gl/qyQ9nKjSHzi1cgav9",
        "location": {
            "address": 'Swefieh Village, Amman.',
            "lat": "31.948385939461296",
            "lng": "35.86476048310845",
        },
        "img": require("../assets/branchphotos/s.jpg"), // Ensure to provide the correct path for the second image
    }
];
