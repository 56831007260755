export const menuBody = [
    { treatment: "Back Massage", minutes: "30 - 45", price: "30 - 40" },
    { treatment: "Aromatherapy", minutes: "55 - 75", price: "45 - 55" },
    { treatment: "Lymphatic Drainage", minutes: 75, price: 60 },
    { treatment: "Le Spa Massage", minutes: "75 - 90", price: "55 - 70" },
    { treatment: "Hot Stone Massage", minutes: 85, price: 70 },
    { treatment: "Bamboo Massage", minutes: 75, price: 60 },
    { treatment: "Swedish", minutes: "55 - 75", price: "45 - 55" },
    { treatment: "Deep Tissue", minutes: "55 - 75", price: "50 - 60" },
    { treatment: "Le Spa Thai", minutes: 75, price: 60 },
    { treatment: "Mum to Be", minutes: 45, price: 45 },
    { treatment: "Scrub & Exfoliate", minutes: "40 - 75", price: "40 - 60" },
    { treatment: "Body Cocoon", minutes: 90, price: 70 },
    { treatment: "Leg Compression", minutes: 60, price: 40 },
    { treatment: "Foot Massage", minutes: "25 - 55", price: "25 - 40" },
    { treatment: "Sole Reflexology", minutes: 30, price: 35 },
    { treatment: "Slimming /RF/Cavitation/", minutes: "price/per package" }
];
