import React, { useState } from 'react';
import Face from './Face';
import Body from './Body';
import Events from './Events';
import Gift from './Gift';
import './Services.css';

const Services = ({ setShowTerms }) => {
    const [expanded, setExpanded] = useState(null);

    const toggleExpand = (index) => {
        setExpanded(expanded === index ? null : index);
    };

    return (
        <div className="services-container">
            <div className="content-wrapper">
                <div className="title">
                    <h1>Our Services</h1>
                </div>
                <div className="services-list">
                    {[
                        { title: "Facial Treatments", content: <Face setShowTerms={setShowTerms} /> },
                        { title: "Body Treatments", content: <Body setShowTerms={setShowTerms} /> },
                        { title: "Special Events", content: <Events /> },
                        { title: "Gift Vouchers", content: <Gift setShowTerms={setShowTerms} /> }
                    ].map((service, index) => (
                        <div key={index}>
                            <div
                                className={`service-item ${expanded === index ? 'active' : ''}`}
                                onClick={() => toggleExpand(index)}
                            >
                                <span className="service-title">{service.title}</span>
                                <span className={`plus-sign ${expanded === index ? 'active' : ''}`}>
                                    +
                                </span>
                            </div>
                            {expanded === index && (
                                <div
                                    className="expanded-content"
                                    onClick={() => toggleExpand(index)}
                                >
                                    {service.content}
                                </div>
                            )}
                            <hr />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Services;
