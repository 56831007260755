import React, { useState, useRef } from 'react';
import { CiMusicNote1 } from 'react-icons/ci';
import { PiPauseThin } from 'react-icons/pi';

import audio from '../assets/music.mp3'; // Correctly import the audio file

const AudioFooter = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(new Audio(audio)); // Use the imported audio file here

    const togglePlayPause = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    return (
        <footer>
            <div onClick={togglePlayPause} style={{ cursor: 'pointer', fontSize: '24px' }}>
                {isPlaying ? <PiPauseThin /> : <CiMusicNote1 />}
            </div>
        </footer>
    );
};

export default AudioFooter;
